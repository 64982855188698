import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import * as actions from '../actions/index'
import Round from '../models/Round';
import _ from 'underscore'
import moment from "../moment-tz"
import { dateFormat } from '../Helper';
import Booking from '../models/Booking';
import { CloudImage } from './CloudImage';
import HospitalRound from './HospitalRound';
import ThingRound from './ThingRound';

class HospitalSetupScreen extends Component {
    constructor() {
        super()
        this.state = {
            rounds: [],
            bookings: [],
            newRoundDate: moment().add(1, 'days').format('YYYY-MM-DD'),
            newRoundQuantityBreakfast: 35,
            newRoundQuantityLunch: 35,
            newRoundQuantityDinner: 35,
            newRoundQuantitySnack: 35,
            newThing: "",
            newQuantity: 0,
            current_round: null
        }
    }
    componentDidMount() {
        if (this.props.match.params.store) {
            Round.init(this.props.match.params.store)
            Booking.init(this.props.match.params.store)

            Round.addListener((items) => this.setState({ rounds: items.filter(x => moment(x.date) >= moment().add(-1, 'days')) }))
            Booking.addListener((items) => this.setState({ bookings: items.filter(x => moment(x.date) >= moment().add(-1, 'days')) }))
        }
    }

    addThing() {
        let { newThing, newQuantity } = this.state
        Round.set(newThing, { title: newThing, quantity: newQuantity, type: 'thing' })
        newThing = ''
        this.setState({ newThing, newQuantity })
    }

    addRound() {
        let { newRoundDate, newRoundQuantityBreakfast, newRoundQuantityLunch, newRoundQuantityDinner, newRoundQuantitySnack } = this.state
        console.log(newRoundDate, moment(newRoundDate))
        Round.set(newRoundDate, { date: newRoundDate, quantityBreakfast: newRoundQuantityBreakfast, quantityLunch: newRoundQuantityLunch, quantityDinner: newRoundQuantityDinner, quantitySnack: newRoundQuantitySnack, type: 'round' })
        newRoundDate = moment(newRoundDate).add(1, 'days').format('YYYY-MM-DD')

        this.setState({ newRoundDate, newRoundQuantityBreakfast, newRoundQuantityLunch, newRoundQuantityDinner, newRoundQuantitySnack })
    }

    render() {
        let { rounds, bookings, newThing, newQuantity, newRoundDate, newRoundQuantityBreakfast, newRoundQuantityLunch, newRoundQuantityDinner, newRoundQuantitySnack, current_round } = this.state
        let timeRounds = rounds.filter(x => !x.type || x.type === 'round')
        let thingRounds = rounds.filter(x => x.type === 'thing')
        let current_round_obj = Round.getItemsById()[current_round]
        return <div className="container m20">
            <h1 className="title mt20">สิ่งของที่เปิดรับบริจาค</h1>
            <div className="round-form-wrapper">
                <div className="columns">
                    <div className="column">สิ่งของ <input type="text" style={{ width: '100%' }} value={newThing} onChange={(e) => this.setState({ newThing: e.currentTarget.value })} /></div>
                    <div className="column">จำนวน <input type="number" style={{ width: '100%' }} value={newQuantity} onChange={(e) => this.setState({ newQuantity: e.currentTarget.value })} /></div>
                    <div className="column"><br /><button className="button is-success is-small is-fullwidth" onClick={this.addThing.bind(this)}>บันทึก</button></div>
                    <div className="column"></div>
                    <div className="column"></div>
                    <div className="column"></div>
                </div>
            </div>
            {_.sortBy(thingRounds, 'date').map(x => <ThingRound item={x} onClick={(e, item, type) => this.setState({ current_round: item, type })} />)}
            <hr />
            <h1 className="title mt20">วันที่เปิดรับอาหาร</h1>
            <div>เลือกวันและจำนวนที่ต้องการเปิดรับ</div>

            <div className="round-form-wrapper">
                <div className="columns">
                    <div className="column">วันที่ <input type="date" style={{ width: '100%' }} value={newRoundDate} onChange={(e) => this.setState({ newRoundDate: e.currentTarget.value })} /></div>
                    <div className="column">เช้า <input type="number" style={{ width: '100%' }} value={newRoundQuantityBreakfast} onChange={(e) => this.setState({ newRoundQuantityBreakfast: e.currentTarget.value })} /></div>
                    <div className="column">กลางวัน <input type="number" style={{ width: '100%' }} value={newRoundQuantityLunch} onChange={(e) => this.setState({ newRoundQuantityLunch: e.currentTarget.value })} /></div>
                    <div className="column">เย็น <input type="number" style={{ width: '100%' }} value={newRoundQuantityDinner} onChange={(e) => this.setState({ newRoundQuantityDinner: e.currentTarget.value })} /></div>
                    <div className="column">ของว่าง <input type="number" style={{ width: '100%' }} value={newRoundQuantitySnack} onChange={(e) => this.setState({ newRoundQuantitySnack: e.currentTarget.value })} /></div>
                    <div className="column"><br /><button className="button is-success is-small is-fullwidth" onClick={this.addRound.bind(this)}>บันทึก</button></div>
                </div>


            </div>
            <br />

            {_.sortBy(timeRounds, 'date').map(x => <HospitalRound item={x} onClick={(e, item, type) => this.setState({ current_round: item, type })} />)}
            {current_round_obj && <div className="reserve-booking-wrapper">
                <h1 className="title mt20">ผู้ประสงค์ถวายภัตตาหารเพล</h1>
                <div className="subtitle mt20">{dateFormat(current_round_obj.date, false)}</div>
                {bookings.filter(x => x.date === current_round_obj.date).map(x => <div className="reserve-booking">
                    <div className="donator-name">{x.donatorName}</div>
                    <div className="donator-phone">โทร: {x.donatorPhone}</div>
                    <div className="donate-quantity">จำนวน {x.donateQuantity}</div>
                    <div className="donate-type">{x.donateType}</div>
                    {x.image && <CloudImage src={x.image} />}
                </div>)}
                <button className="button is-fullwidth is-info mt10" onClick={() => this.setState({ current_round: null })}>ย้อนกลับ</button>
            </div>}
            {/* <a className="button is-success" href={`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=dQjcjPiEJCGCx6g2IZnnqb&redirect_uri=https://us-central1-rine-hitevent.cloudfunctions.net/lineNotifyCallback&scope=notify&state=วัดเทวราชกุญชรวรวิหาร&response_mode=form_post`}>Link Line Notification</a> */}
        </div>
    }
}

const mapStateToProps = ({ current_user }) => ({ current_user });

export default withRouter(connect(mapStateToProps, actions)(HospitalSetupScreen))
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import * as actions from '../actions/index'
import Round from '../models/Round';
import moment from "../moment-tz"
import _ from 'underscore'
import { dateFormat } from '../Helper';
import Booking from '../models/Booking';
import { CloudImage } from './CloudImage';
import Store from '../models/Store';
import TempleSetupScreen from './TempleSetupScreen';
import HospitalSetupScreen from './HospitalSetupScreen';

class SetupScreen extends Component {

    constructor(props) {
        super(props)

        this.state = {}
    }
    async componentDidMount() {
        if (this.props.match.params.store) {
            let store_obj = await Store.get(this.props.match.params.store)
            let store = await store_obj.data()
            store.id = store_obj.id;
            this.setState({ store })
        }
    }

    render() {
        let { store } = this.state
        if (store) {
            if (store.type === 'temple') {
                return <TempleSetupScreen />
            }
        }
        return <HospitalSetupScreen />
    }
}

const mapStateToProps = ({ current_user }) => ({ current_user });

export default withRouter(connect(mapStateToProps, actions)(SetupScreen))
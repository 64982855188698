import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, NavLink } from "react-router-dom";

import logo from './logo.svg';
import '@fortawesome/fontawesome-free/css/all.css'
import 'bulma/css/bulma.css'
import './App.scss';

import Header from './components/Header';
import Home from './components/Home';
import SetupScreen from './components/SetupScreen';
import PaymentScreen from './components/PaymentScreen';
import Directory from './components/Directory';

class App extends Component {
  constructor(props) {
    super()

    this.state = {}

  }
  render() {

    return (
      <div className="App">

        <Router>
          <div className="container">
            <Header />
            <Switch>
              <Route path="/payment/:booking_id"><PaymentScreen /></Route>
              <Route path="/:store/setup"><SetupScreen /></Route>
              <Route path="/:store"><Home /></Route>
              <Route exact path="/"><Directory /></Route>
            </Switch>
          </div>
        </Router>
        <footer>Powered by HitEvent</footer>
      </div>
    );
  }
}

export default App

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import * as actions from '../actions/index'
import Round from '../models/Round';
import moment from "../moment-tz"
import _ from 'underscore'
import { dateFormat } from '../Helper';
import Booking from '../models/Booking';
import { CloudImage } from './CloudImage';

class TempleSetupScreen extends Component {
    constructor() {
        super()
        this.state = {
            rounds: [],
            bookings: [],
            newRoundDate: moment().add(1, 'days').format('YYYY-MM-DD'),
            newRoundQuantity: 35,
            current_round: null
        }
    }
    componentDidMount() {
        if (this.props.match.params.store) {
            Round.init(this.props.match.params.store)
            Booking.init(this.props.match.params.store)

            Round.addListener((items) => this.setState({ rounds: items.filter(x => moment(x.date) >= moment().add(-1, 'days')) }))
            Booking.addListener((items) => this.setState({ bookings: items.filter(x => moment(x.date) >= moment().add(-1, 'days')) }))
        }
    }

    addRound() {
        let { newRoundDate, newRoundQuantity } = this.state
        console.log(newRoundDate, moment(newRoundDate))
        Round.set(newRoundDate, { date: newRoundDate, quantity: newRoundQuantity })
        newRoundDate = moment(newRoundDate).add(1, 'days').format('YYYY-MM-DD')

        this.setState({ newRoundDate, newRoundQuantity })
    }

    render() {
        let { rounds, bookings, newRoundDate, newRoundQuantity, current_round } = this.state
        let current_round_obj = Round.getItemsById()[current_round]
        return <div className="container m20">
            <h1 className="title mt20">วันที่เปิดให้มีการถวายเพล</h1>
            <div>เลือกวันและจำนวนพระที่เปิดให้มีการถวายเพล</div>

            <div className="round-form-wrapper">
                วันที่ <input type="date" value={newRoundDate} onChange={(e) => this.setState({ newRoundDate: e.currentTarget.value })} />
                จำนวน <input type="number" value={newRoundQuantity} onChange={(e) => this.setState({ newRoundQuantity: e.currentTarget.value })} />
                <button className="button is-success is-small" onClick={this.addRound.bind(this)}>บันทึก</button>
            </div>
            <br />
            {_.sortBy(rounds, 'date').map(x => <div className={`round-wrapper has-head-weekday${moment(x.date).weekday()}`} onClick={() => this.setState({ current_round: x.id })}>
                <div className="round-date">{dateFormat(x.date, false)}</div>
                <div className="round-quantity">{x.reserved || 0}/{x.quantity}</div>
            </div>)}
            {current_round_obj && <div className="reserve-booking-wrapper">
                <h1 className="title mt20">ผู้ประสงค์ถวายภัตตาหารเพล</h1>
                <div className="subtitle mt20">{dateFormat(current_round_obj.date, false)}</div>
                {bookings.filter(x => x.date === current_round_obj.date).map(x => <div className="reserve-booking">
                    <div className="donator-name">{x.donatorName}</div>
                    <div className="donator-phone">โทร: {x.donatorPhone}</div>
                    <div className="donate-quantity">จำนวน {x.donateQuantity} รูป</div>
                    <div className="donate-type">{x.donateType}</div>
                    {x.image && <CloudImage src={x.image} />}
                </div>)}
                <button className="button is-fullwidth is-info mt10" onClick={() => this.setState({ current_round: null })}>ย้อนกลับ</button>
            </div>}
            {/* <a className="button is-success" href={`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=dQjcjPiEJCGCx6g2IZnnqb&redirect_uri=https://us-central1-rine-hitevent.cloudfunctions.net/lineNotifyCallback&scope=notify&state=วัดเทวราชกุญชรวรวิหาร&response_mode=form_post`}>Link Line Notification</a> */}
        </div>
    }
}

const mapStateToProps = ({ current_user }) => ({ current_user });

export default withRouter(connect(mapStateToProps, actions)(TempleSetupScreen))
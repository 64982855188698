import * as firebase from 'firebase';
import FirebaseModel from './FirebaseModel';
var db = firebase.firestore();

export default class StoreFirebaseModel extends FirebaseModel {
    static init(store) {
        this.setStore(store)
        return this
    }

    static setStore(store) {
        this.store = store;
        return this
    }

    static getCollection(store) {
        if (store) {
            return db.collection('stores').doc(store).collection(this.collection)
        } else {
            return db.collection('stores').doc(this.store).collection(this.collection)
        }
    }
}

StoreFirebaseModel.collection = 'products';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import _ from 'underscore'
import moment from "../moment-tz"
import * as firebase from 'firebase';
import * as AppConfig from '../AppConfig.json';
import * as actions from '../actions/index'

import { dateFormat } from '../Helper';
import Round from '../models/Round';
import Booking from '../models/Booking';
import Store from '../models/Store';
import ImageUploadInput from './inputs/ImageUploadInput';
import TempleRound from './TempleRound';
import HospitalRound from './HospitalRound';
import ThingRound from './ThingRound';

class Home extends Component {
    constructor() {
        super()
        this.state = { rounds: [], current_round: null, donateQuantity: 1, loading: false }

        window.liff.init({
            liffId: AppConfig.liffId
        }).then(() => {
            if (!window.liff.isLoggedIn()) {

                ///// window.location.href = "https://liff.line.me/1653970986-wK4klqRP"
                // window.location.href = `line://app/${AppConfig.liffId}`
                // setTimeout(() => {
                //     window.liff.login();
                // }, 1000)
            } else {
                const context = window.liff.getContext();
                if (context) {
                    const userId = context.userId;
                    this.props.setLineUser(userId);
                    // 	U9d72ae54455c1aafb09e3b7ed7c81bfc
                    firebase.firestore().collection('line_user').doc(userId).set({ userId });

                }
            }
        }).catch((err) => {
            // Error happens during initialization
            alert(err.code + err.message);
        });
    }

    async componentDidMount() {
        if (this.props.match.params.store) {
            Round.init(this.props.match.params.store)
            Booking.init(this.props.match.params.store)
            Round.addListener((items) => this.setState({ rounds: items.filter(x => moment(x.date) >= moment()) }))
            let store_obj = await Store.get(this.props.match.params.store)
            let store = await store_obj.data()
            store.id = store_obj.id;
            this.setState({ store })
        }
    }

    async save(obj) {
        let { donatorName, donatorPhone, donateQuantity, donateType, selectedImageFile, store, type } = this.state
        debugger
        let lineUserId = this.props.line_user
        if (obj.type === 'thing') {
            let title = obj.title;
            if (donatorName && donatorPhone && donateQuantity) {
                let booking = booking = await Booking.add({ donatorName, donatorPhone, donateQuantity, title, lineUserId, store: store.id })

                donatorName = null;
                donatorPhone = null;
                donateQuantity = 1;
                donateType = null;


                alert("ข้อมูลได้ถูกส่งเรียบร้อยแล้ว")

                this.setState({ current_round: null, loading: false })
            }
        }
        else {
            let date = obj.date;

            await this.setState({ loading: true })
            if (donatorName && donatorPhone && donateQuantity && donateType) {

                let booking = null;
                if (type) {
                    booking = await Booking.add({ donatorName, donatorPhone, donateQuantity, donateType, date, lineUserId, store: store.id, type })
                }
                else {
                    booking = await Booking.add({ donatorName, donatorPhone, donateQuantity, donateType, date, lineUserId, store: store.id })
                }

                if (selectedImageFile && selectedImageFile.name) {
                    let ext = selectedImageFile.name.split('.')[1]
                    let storageRef = firebase.storage().ref();
                    let image = `payments/${booking.id}.${ext}`
                    let fileRef = storageRef.child(image);
                    await fileRef.put(selectedImageFile)
                    await booking.update({ image })
                }

                if (donateType === 'zen') {
                    window.location.href = `https://liff.line.me/1653970986-wK4klqRP/zencatering?customer=${donatorName}&destination=${store && store.name}&phone=${donatorPhone}&note=${encodeURIComponent(`สั่งอาหารบริจาคผ่าน จำนวน ${donateQuantity} ชุด`)}&date=${date}&location=13.7717667,100.49979549999999`
                }
                /*else {
                    
                    try {
                        let liffMessage = {
                            "type": "bubble",
                            "hero": {
                                "type": "image",
                                "url": "https://foremonk.hitevent.com/header.jpg",
                                "size": "full",
                                "aspectRatio": "20:13",
                                "aspectMode": "cover",
                                "action": {
                                    "type": "uri",
                                    "uri": "https://foremonk.hitevent.com"
                                }
                            },
                            "body": {
                                "type": "box",
                                "layout": "vertical",
                                "contents": [
                                    {
                                        "type": "text",
                                        "text": "ยืนยันการแจ้งความประสงค์ถวายภัตตาหารเพล",
                                        "weight": "bold",
                                        "size": "lg",
                                        "wrap": true
                                    },
                                    {
                                        "type": "box",
                                        "layout": "vertical",
                                        "margin": "lg",
                                        "spacing": "sm",
                                        "contents": [
                                            {
                                                "type": "text",
                                                "text": "วัดเทวราชกุญชรวรวิหาร"
                                            },
                                            {
                                                "type": "box",
                                                "layout": "baseline",
                                                "spacing": "sm",
                                                "contents": [
                                                    {
                                                        "type": "text",
                                                        "text": "วัน",
                                                        "color": "#aaaaaa",
                                                        "size": "sm",
                                                        "flex": 1
                                                    },
                                                    {
                                                        "type": "text",
                                                        "text": "date",
                                                        "wrap": true,
                                                        "color": "#666666",
                                                        "size": "sm",
                                                        "flex": 4
                                                    }
                                                ]
                                            },
                                            {
                                                "type": "box",
                                                "layout": "baseline",
                                                "spacing": "sm",
                                                "contents": [
                                                    {
                                                        "type": "text",
                                                        "text": "จำนวน",
                                                        "color": "#aaaaaa",
                                                        "size": "sm",
                                                        "flex": 1
                                                    },
                                                    {
                                                        "type": "text",
                                                        "wrap": true,
                                                        "color": "#666666",
                                                        "size": "sm",
                                                        "flex": 4,
                                                        "text": `${donateQuantity} รูป`
                                                    }
                                                ]
                                            },
                                            {
                                                "type": "box",
                                                "layout": "baseline",
                                                "spacing": "sm",
                                                "contents": [
                                                    {
                                                        "type": "text",
                                                        "text": "โดย",
                                                        "color": "#aaaaaa",
                                                        "size": "sm",
                                                        "flex": 1
                                                    },
                                                    {
                                                        "type": "text",
                                                        "wrap": true,
                                                        "color": "#666666",
                                                        "size": "sm",
                                                        "flex": 4,
                                                        "text": "ประสงค์บริจาคเพื่อให้ทางวัดจัดหาให้ ( รูปละ 150 บาท )"
                                                    }
                                                ]
                                            }
                                        ],
                                        "paddingBottom": "10px"
                                    }
                                ]
                            }
                        }

                        let liffTransferMessage = {
                            "type": "bubble",
                            "body": {
                                "type": "box",
                                "layout": "vertical",
                                "contents": [
                                    {
                                        "type": "box",
                                        "layout": "vertical",
                                        "margin": "lg",
                                        "spacing": "sm",
                                        "contents": [
                                            {
                                                "type": "text",
                                                "text": "ท่านสามารถโอนเงินบริจาคทางบัญชีธนาคารกรุงไทย",
                                                "wrap": true
                                            },
                                            {
                                                "type": "box",
                                                "layout": "baseline",
                                                "spacing": "sm",
                                                "contents": [
                                                    {
                                                        "type": "text",
                                                        "text": "ชื่อบัญชี",
                                                        "color": "#aaaaaa",
                                                        "size": "sm",
                                                        "flex": 1,
                                                        "wrap": true
                                                    },
                                                    {
                                                        "type": "text",
                                                        "text": "วัดเทวราชกุญชรวรวิหาร",
                                                        "wrap": true,
                                                        "color": "#666666",
                                                        "size": "sm",
                                                        "flex": 4
                                                    }
                                                ]
                                            },
                                            {
                                                "type": "box",
                                                "layout": "baseline",
                                                "spacing": "sm",
                                                "contents": [
                                                    {
                                                        "type": "text",
                                                        "text": "สาขา",
                                                        "color": "#aaaaaa",
                                                        "size": "sm",
                                                        "flex": 1,
                                                        "wrap": true
                                                    },
                                                    {
                                                        "type": "text",
                                                        "wrap": true,
                                                        "color": "#666666",
                                                        "size": "sm",
                                                        "flex": 4,
                                                        "text": "สาขากระทรวงศึกษาธิการ"
                                                    }
                                                ]
                                            },
                                            {
                                                "type": "box",
                                                "layout": "baseline",
                                                "spacing": "sm",
                                                "contents": [
                                                    {
                                                        "type": "text",
                                                        "text": "เลขที่",
                                                        "color": "#aaaaaa",
                                                        "size": "sm",
                                                        "flex": 1,
                                                        "wrap": true
                                                    },
                                                    {
                                                        "type": "text",
                                                        "wrap": true,
                                                        "color": "#666666",
                                                        "size": "sm",
                                                        "flex": 4,
                                                        "text": "059-0-33444-1"
                                                    }
                                                ]
                                            },
                                            {
                                                "type": "separator",
                                                "margin": "xxl"
                                            }
                                        ],
                                        "paddingBottom": "10px"
                                    },
                                    {
                                        "type": "text",
                                        "text": `เมื่อท่านโอนเงินแล้ว จำนวน ${donateQuantity * 150} บาท`,
                                        "wrap": true
                                    },
                                    {
                                        "type": "text",
                                        "text": "สามารถส่งภาพหลักฐานการโอนเงิน\nโดยกดที่ปุ่มด้านล่าง หรือ \nแจ้งทางวัดผ่านเบอร์ 02 281 2430",
                                        "size": "sm",
                                        "wrap": true,
                                        "color": "#888888"
                                    }
                                ]
                            },
                            "footer": {
                                "type": "box",
                                "layout": "vertical",
                                "spacing": "sm",
                                "contents": [
                                    {
                                        "type": "button",
                                        "style": "primary",
                                        "height": "sm",
                                        "action": {
                                            "type": "uri",
                                            "label": "แจ้งการโอนเงิน",
                                            "uri": `https://liff.line.me/${AppConfig.liffId}/payment/${booking.id}`
                                        }
                                    },
                                    {
                                        "type": "spacer",
                                        "size": "sm"
                                    }
                                ],
                                "flex": 0
                            }
                        }

                        let pushMessage = [{
                            "type": "flex",
                            "altText": "ยืนยันการแจ้งความประสงค์ถวายภัตตาหารเพล",
                            "contents": liffMessage
                        }]
                        if (donateType === 'ประสงค์ให้ทางวัดจัดหาให้') {
                            pushMessage.push({
                                "type": "flex",
                                "altText": "การโอนเงิน",
                                "contents": liffTransferMessage
                            })
                        }

                        fetch('https://us-central1-doliver-hitevent.cloudfunctions.net/line_bot_push', { // Your POST endpoint
                            mode: 'no-cors',
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                userId: lineUserId,
                                message: pushMessage
                            }) // This is your file object
                        }).then(
                            resp => resp.json() // if the response is a JSON object
                        ).then(
                            success => console.log(success) // Handle the success response object
                        ).catch(
                            error => console.log(error) // Handle the error response object
                        );

                    } catch (error) {
                        console.log(error)
                    }
*/
                /*window.liff.sendMessages([{
                    type: 'text',
                    text: `เมื่อท่านโอนเงินแล้ว สามารถส่งภาพหลักฐานการโอนเงินผ่านลิงค์ https://liff.line.me/1653970986-wK4klqRP/payment/${booking.id} หรือ แจ้งทางวัดผ่านเบอร์ 02 281 2430`
                }])
                    .then(() => {
                        // console.log('บันทึกเวลา #' + place + "\n" +
                        //     `${day}-${month}-${year} ${hour}:${minute}`);
                        window.liff.closeWindow();
                    })
                    .catch((err) => {
                        document.write(JSON.stringify(err));
                    });
            }*/

                donatorName = null;
                donatorPhone = null;
                donateQuantity = 1;
                donateType = null;

                alert("ข้อมูลได้ถูกส่งให้เรียบร้อยแล้ว")

                this.setState({ current_round: null, loading: false })
            }
        }
    }

    renderRound(item) {
        if (item.type === 'thing') {
            return <ThingRound item={item} onClick={(e, item, type) => this.setState({ current_round: item, type })} />
        }
        else if (item.quantity > 0) {
            return <TempleRound item={item} onClick={(e, item, type) => this.setState({ current_round: item, type })} />
        }
        else if (item.quantityBreakfast > 0 && item.quantityLunch > 0 && item.quantityDinner > 0 && item.quantitySnack > 0) {

            return <HospitalRound item={item} onClick={(e, item, type) => this.setState({ current_round: item, type })} />
        }

        return false;
    }
    typeLabel(type) {
        let labels = { breakfast: 'มื้อเช้า', lunch: 'มื้อกลางวัน', dinner: 'มือเย็น', snack: 'อาหารว่าง' }
        return labels[type]
    }
    render() {
        let { rounds, current_round, donatorName, donatorPhone, donateQuantity, donateType, selectedImageData, selectedImageFile, loading, store, type } = this.state

        let current_round_obj = Round.getItemsById()[current_round]
        console.log(current_round, current_round_obj)

        let left = 0

        if (current_round_obj) {
            if (store.type === 'hospital' && current_round_obj.type !== 'thing') {
                if (type === 'breakfast') {
                    left = current_round_obj.quantityBreakfast - (current_round_obj.reservedBreakfast || 0)
                }
                if (type === 'lunch') {
                    left = current_round_obj.quantityLunch - (current_round_obj.reservedLunch || 0)
                }
                if (type === 'dinner') {
                    left = current_round_obj.quantityDinner - (current_round_obj.reservedDinner || 0)
                }
                if (type === 'snack') {
                    left = current_round_obj.quantitySnack - (current_round_obj.reservedSnack || 0)
                }
            }
            else {
                left = current_round_obj.quantity - (current_round_obj.reserved || 0)
            }
        }
        if (left < 0) {
            left = 0;
        }

        let timeRounds = rounds.filter(x => !x.type || x.type === 'round')
        let thingRounds = rounds.filter(x => x.type === 'thing')


        return <div className="container m20">
            {loading && <div className="loading">กำลังส่งข้อมูล</div>}
            {/* <img src="./header.jpg" style={{ width: '100%' }} /> */}
            {/* <h1 className="title mt20">แจ้งความประสงค์ถวายภัตตาหารเพล</h1> */}
            <h1 className="title mt20 is-5 has-text-centered">
                ท่านผู้มีความประสงค์บริจาคอาหารหรือสิ่งของ<br />
                <div className="hilight">{store && store.name}</div>
                สามารถแจ้งความประสงค์ได้โดยเลือกรายการสิ่งของ หรือ วันเวลาด้านล่าง
            </h1>
            <hr />
            {thingRounds.length > 0 && <h2 className="title mt20 is-5 has-text-left">รายการสิ่งของ</h2>}
            {thingRounds.length > 0 && _.sortBy(thingRounds, 'title').map(x => this.renderRound(x))}
            {timeRounds.length > 0 && <h2 className="title mt20 is-5 has-text-left">วันที่เปิดรับอาหาร</h2>}
            {_.sortBy(timeRounds, 'date').map(x => this.renderRound(x))}
            {current_round_obj && left > 0 && <div className="reserve-form-wrapper container">
                {/* <img src="./header.jpg" style={{ width: '100%' }} /> */}
                {store.type === 'temple' && <h1 className="title mt20 mb5 is-5 has-text-centered">แจ้งความประสงค์ถวายภัตตาหารเพล</h1>}
                {store.type !== 'temple' && <h1 className="title mt20 mb5 is-5 has-text-centered">แจ้งความประสงค์บริจาค</h1>}
                <div className="subtitle m10 is-6 has-text-centered hilight">{dateFormat(current_round_obj.date, false)}</div>
                {type && <div className="subtitle m10 is-6 has-text-centered hilight">{this.typeLabel(type)}</div>}
                <div class="field">
                    <label class="label hilight">ชื่อ-นามสกุล</label>
                    <div class="control">
                        <input class="input" type="text" value={donatorName} onChange={(e) => this.setState({ donatorName: e.currentTarget.value })} />
                    </div>
                </div>
                <div class="field">
                    <label class="label hilight">เบอร์ที่ติดต่อได้สะดวก(ใส่เฉพาะตัวเลข)</label>
                    <div class="control">
                        <input class="input" type="tel" value={donatorPhone} onChange={(e) => this.setState({ donatorPhone: e.currentTarget.value })} />
                    </div>
                    <p className="help">ถ้าหากไม่สามารถติดต่อได้ อาจขอยกเลิกการแจ้งความประสงค์นี้</p>
                </div>
                {store.type === 'temple' && <div>
                    <div class="field">
                        <label class="label hilight">จำนวนพระที่ประสงค์จะถวาย (จำนวนรูป)</label>
                        <div class="control">
                            <select className="select input" value={donateQuantity} onChange={(e) => this.setState({ donateQuantity: e.currentTarget.value })} >
                                {[...Array(left)].map((x, i) => <option value={i + 1}>{i + 1}</option>)}
                            </select>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">รูปแบบ</label>
                        <div class="control">
                            <input class="radio" type="radio" name="channel" checked={donateType === 'ประสงค์ส่งภัตตาหารเป็นกล่องไปให้กับทางวัด'} onClick={() => this.setState({ donateType: 'ประสงค์ส่งภัตตาหารเป็นกล่องไปให้กับทางวัด' })} /> ประสงค์ส่งภัตตาหารเป็นกล่องไปให้กับทางวัด<br />
                            <input class="radio" type="radio" name="channel" checked={donateType === 'ประสงค์ให้ทางวัดจัดหาให้'} onClick={() => this.setState({ donateType: 'ประสงค์ให้ทางวัดจัดหาให้' })} /> ประสงค์บริจาคเพื่อให้ทางวัดจัดหาให้ ( รูปละ 150 บาท )<br />
                            <input class="radio" type="radio" name="channel" checked={donateType === 'zen'} onClick={() => this.setState({ donateType: 'zen' })} /> ประสงค์จะสั่งอาหารจากทาง Zen Catering
                    </div>
                    </div>
                </div>}

                {store.type !== 'temple' && current_round_obj.type !== 'thing' && <div>
                    <div class="field">
                        <label class="label hilight">จำนวนเจ้าหน้าที่ที่ต้องการบริจาคให้</label>
                        <div class="control">
                            <input type="number" className="input" value={donateQuantity} onChange={(e) => this.setState({ donateQuantity: e.currentTarget.value })} />
                        </div>
                        <div class="field">
                            <label class="label">รูปแบบ</label>
                            <div class="control">
                                <input class="radio" type="radio" name="channel" checked={donateType === 'ประสงค์ส่งอาหารให้'} onClick={() => this.setState({ donateType: 'ประสงค์ส่งอาหารให้' })} /> ประสงค์ส่งอาหารให้<br />
                                <input class="radio" type="radio" name="channel" checked={donateType === 'zen'} onClick={() => this.setState({ donateType: 'zen' })} /> ประสงค์จะสั่งอาหารจากทาง Zen Catering
                            </div>
                        </div>
                    </div>
                </div>}
                {store.type !== 'temple' && current_round_obj.type === 'thing' && <div class="field">
                    <label class="label hilight">สิ่งของที่จะบริจาค</label>
                    <div className="columns is-mobile">
                        <div className="column is-two-thirds">
                            <input type="text" className="input" value={current_round_obj.title} disabled />
                        </div>
                        <div className="column is-one-thirds">
                            <input type="number" className="input has-text-right" value={donateQuantity} onChange={(e) => this.setState({ donateQuantity: e.currentTarget.value })} />
                        </div>
                    </div>
                    {/* <button className="button is-warning is-small is-fullwidth hilight is-outlined">+ เพิ่มสิ่งของที่ต้องการบริจาค</button> */}
                </div>}

                {donateType === 'zen' && <div className="channel-description has-text-centered">
                    <h3>ประสงค์จะสั่งอาหารจากทาง Zen Catering</h3>
                    {/* <div className="column">
                            <img src="./demo.jpg" style={{ width: '100%', maxWidth: '300px' }} />
                        </div> */}
                        ท่านสามารถเลือกเมนูอาหารจาก Catering by Zen ได้หลังจากส่งข้อมูล<br />
                    <img src="/zen.jpg" style={{ width: '100%', maxWidth: '400px' }} /><br />
                        ทาง Catering by Zen จะติดต่อท่านอีกครั้ง เผื่อรับชำระเงิน และ ส่งอาหารให้กับทางวัดในวันที่ท่านได้แจ้งความประสงค์ไว้

                </div>}
                {donateType === 'ประสงค์ให้ทางวัดจัดหาให้' && <div className="channel-description">
                    <h3>ประสงค์บริจาคเพื่อให้ทางวัดจัดหาให้</h3>
                    ท่านสามารถโอนเงินบริจาคทางบัญชีธนาคารกรุงไทย<br />
                    <div className="columns">
                        <div className="column">
                            <img src="./bank_logo.jpg" style={{ width: '100%', maxWidth: '300px' }} />
                        </div>
                        <div className="column">
                            ชื่อบัญชี: <b>วัดเทวราชกุญชรวรวิหาร</b><br />
                            สาขา: <b>สาขากระทรวงศึกษาธิการ</b><br />
                            เลขที่บัญชี: <b>059-0-33444-1</b><br />
                            จำนวนเงิน: <b>{donateQuantity * 150}</b> บาท
                            <br />
                            <br />
                            จากนั้นกดที่ปุ่มด่านล่างเพื่อทำการเลือกภาพหลักฐานการโอนเงิน หรือ แจ้งทางวัดผ่านเบอร์ 02 281 2430<br />
                            <div class="upload-btn-wrapper">
                                <button class="button is-info is-fullwidth">{!selectedImageData ? 'เลือกภาพหลักฐานการโอนเงิน' : 'เลือกภาพหลักฐานการโอนเงินใหม่'}</button>
                                <ImageUploadInput onFileSelect={selectedImageFile => this.setState({ selectedImageFile })} onBase64Data={selectedImageData => this.setState({ selectedImageData })} />
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="columns is-mobile">
                    <div className="column">
                        <button className="button is-fullwidth is-outlined" onClick={() => this.setState({ current_round: null })}>ย้อนกลับ</button>
                    </div>
                    <div className="column">
                        <button className="button is-fullwidth is-success" onClick={() => this.save(current_round_obj)} disabled={!(donatorName && donatorPhone && donateQuantity && (current_round_obj.type === 'thing' || donateType))}>ส่งข้อมูล</button>
                    </div>
                </div>
            </div>}
        </div>;
    }
}

const mapStateToProps = ({ current_user, line_user }) => ({ current_user, line_user });

export default withRouter(connect(mapStateToProps, actions)(Home))
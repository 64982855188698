import React from 'react';
import moment from "../moment-tz"
import { dateFormat } from '../Helper';

export default function HospitalRound(props) {
    let { item } = props
    return <div className={`round-wrapper has-head-weekday${moment(item.date).weekday()}`} >
        <div className="round-date">{dateFormat(item.date, false)}</div>
        <div className="columns round-times is-mobile">
            <div className="column round-time" onClick={(e) => props.onClick && props.onClick(e, item.id, 'breakfast')}>
                <img src="/breakfast-time.png" />
                <div className="time-title">มื้อเช้า</div>
                <div className="time-quantity">{item.reservedBreakfast || 0}/{item.quantityBreakfast}</div>
            </div>

            <div className="column round-time" onClick={(e) => props.onClick && props.onClick(e, item.id, 'lunch')}>
                <img src="/lunch-time.png" />
                <div className="time-title">มื้อกลางวัน</div>
                <div className="time-quantity">{item.reservedLunch || 0}/{item.quantityLunch}</div>
            </div>
            <div className="column round-time" onClick={(e) => props.onClick && props.onClick(e, item.id, 'dinner')}>
                <img src="/dinner-time.png" />
                <div className="time-title">มื้อเย็น</div>
                <div className="time-quantity">{item.reservedDinner || 0}/{item.quantityDinner}</div>
            </div>
            <div className="column round-time" onClick={(e) => props.onClick && props.onClick(e, item.id, 'snack')}>
                <img src="/breakfast-time.png" />
                <div className="time-title">ของว่าง</div>
                <div className="time-quantity">{item.reservedSnack || 0}/{item.quantitySnack}</div>
            </div>
        </div>
    </div>;
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import _ from 'underscore'
import moment from "../moment-tz"
import * as firebase from 'firebase';
import * as AppConfig from '../AppConfig.json';
import * as actions from '../actions/index'

import { dateFormat } from '../Helper';
import Round from '../models/Round';
import Booking from '../models/Booking';
import ImageUploadInput from './inputs/ImageUploadInput';
import Store from '../models/Store';

class Directory extends Component {

    constructor() {
        super()
        this.state = { stores: false }
    }

    async componentDidMount() {
        Store.addListener((stores, stores_by_id) => this.setState({ stores: stores_by_id }))
    }

    render() {
        let { stores, searchquery } = this.state;
        if (stores && searchquery) {
            let filtered = Object.keys(stores).filter(x => stores[x].name.includes(searchquery))
            let filteredData = {}
            for (var f in filtered) {
                filteredData[filtered[f]] = stores[filtered[f]]
            }
            // stores = stores.filter(x => x.name.includes(searchquery))
            stores = filteredData
        }

        if (stores === false) {
            return ""
        }

        return <div>
            <div className="has-text-centered">
                <img src="/header.png" style={{ width: '100%', maxWidth: '400px' }} />
                <h1 className="title is-4 mt20">
                    แหล่งรวบรวมข้อมูลการขอรับบริจาคสิ่งของและอาหาร<br />
                    ร่วมกันบริจาคอาหาร<br />
                    ถวายวัดและโรงพยาบาล ผ่านช่องทางออนไลน์
                </h1>
                <h2 className="subtitle hilight mt10">- เลือกสถานที่บริจาค -</h2>
                <div className="container p10 searchbox">
                    <input className="input" type="text" placeholder="ค้นหาสถานพยาบาล, วัด, มูลนิธิ" value={searchquery} onChange={e => this.setState({ searchquery: e.currentTarget.value })} />
                    <i className="search-icon fas fa-search"></i>
                </div>
                <div className="container p20">
                    <div className="store-list columns is-multiline is-mobile">
                        {stores && Object.keys(stores).map(x =>
                            <div className="store column is-full animated fadeIn">
                                <Link to={`/${x}`}>
                                    <div className={stores[x].type}>
                                        <img src={`/${stores[x].type}.png`} />
                                        <div className="store-name">{stores[x].name}</div>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    }
}

const mapStateToProps = ({ current_user, line_user }) => ({ current_user, line_user });

export default withRouter(connect(mapStateToProps, actions)(Directory))
import React from 'react';
import moment from "../moment-tz"
import { dateFormat } from '../Helper';

export default function TempleRound(props) {
    let { item } = props
    return <div className={`round-wrapper has-head-weekday${moment(item.date).weekday()}`} onClick={(e) => props.onClick && props.onClick(e, item.id)}>
        <div className="round-date">{dateFormat(item.date, false)}</div>
        <div className="round-quantity">{item.reserved || 0}/{item.quantity}</div>
    </div>;
}